import React from "react";
import { cn } from "@/helpers/classNames";

export interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  tag?: "div" | "section" | "aside" | "header" | "article" | "nav" | "figure";
}

const Container: React.FC<ContainerProps> = ({
  tag = "div",
  children = undefined,
  className,
  ...props
}) => {
  const Wrapper = tag;

  return (
    <Wrapper
      className={cn("mx-auto max-w-screen-lg px-md", className)}
      {...props}
    >
      {children}
    </Wrapper>
  );
};

export default Container;
