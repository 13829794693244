import React from "react";
import Link, { LinkProps } from "@/components/link";
import { cn } from "@/helpers/classNames";

export type ButtonProps = {
  to?: LinkProps["to"];
  type?: "button" | "submit";
  disabled?: boolean;
  defaultClassNames?: boolean;
} & (
  | Omit<React.HTMLAttributes<HTMLButtonElement>, "disabled" | "type">
  | Omit<LinkProps, "to" | "type">
);

const Button: React.FC<ButtonProps> = ({
  to = undefined,
  type = "button",
  disabled = false,
  defaultClassNames = true,
  children = undefined,
  className,
  ...passedProps
}) => {
  const props = {
    className: cn(
      defaultClassNames
        ? // className=
          "inline-flex items-center flex-none text-sm font-medium text-center text-white no-underline transition duration-500 ease-in-out rounded select-none text-base/none shrink-0 py-3f px-4f bg-accent-500 hover:text-white hover:shadow-xl hover:bg-accent-600 hover:shadow-accent-400/10 dark:text-black dark:hover:text-black dark:hover:bg-accent-400 md:text-base"
        : undefined,
      className,
    ),
    ...passedProps,
  };

  if (to) {
    return (
      <Link to={to} {...(props as Omit<LinkProps, "to">)}>
        {children}
      </Link>
    );
  }

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      disabled={disabled}
      {...(props as React.HTMLAttributes<HTMLButtonElement>)}
    >
      {children}
    </button>
  );
};

export default Button;
