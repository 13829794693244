import React from "react";
import Container from "@/components/container";
import type { LinkProps } from "@/components/link";
import Link from "@/components/link";
import LogoShort from "@/layouts/partials/logo-short";

const NavLink: React.FC<LinkProps> = ({ children = undefined, ...props }) => (
  <Link
    className="no-underline transition-colors duration-500 ease-in-out text-inherit hover:text-accent-500"
    {...props}
  >
    {children}
  </Link>
);

const Footer: React.FC = () => (
  <footer>
    <Container className="flex flex-col text-base gap-md py-lg md:gap-gutter md:flex-row">
      <Link
        to="/"
        className="text-accent-500 mb-sm md:mb-0"
        aria-label="Zur Startseite"
        title="Zur Startseite"
      >
        <LogoShort />
      </Link>
      <div>&copy;&nbsp;{new Date().getFullYear()} Link4Health GmbH</div>
      <nav className="md:ml-auto">
        <ul className="flex flex-col gap-sm sm:flex-row sm:gap-base">
          <li>
            <NavLink to="/datenschutz">Datenschutz</NavLink>
          </li>
          <li>
            <NavLink to="/impressum">Impressum</NavLink>
          </li>
        </ul>
      </nav>
    </Container>
  </footer>
);

export default Footer;
