import React from "react";

type LogoShortProps = {
  className?: string;
};

const LogoShort: React.FC<LogoShortProps> = ({ ...props }) => (
  <svg
    height="24"
    viewBox="0 0 64 24"
    width="64"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m0 0v23.99934h15.3355552v-4.1827657h-10.27091849v-19.8165743zm28.700388 5.53032574h-.1877621l-6.3048551 9.99738746v.1864507h6.4926172zm8.8095032 14.25027846h-4.0599445l-.0009467-4.0666042-4.748612.0001639-.000388 4.0658361 2.4210373.0006042v4.2190658h-4.8422868l-.0007505-4.21867-9.1694537.0004292v-3.996315l10.0008046-15.7839592h6.3410899l-.0004408 15.711845 4.0598912.0003389v-4.0656152h4.0599446v4.0672652h-4.0599446zm6.0890933-9.87858707h5.0646367v-9.90168713h-5.0646367zm0 14.09765287h5.0646367v-9.9132372h-5.0646367zm15.347908-23.99901v9.90168713h-10.2824477v4.18441567h10.2824477v9.9132372h5.0531075v-23.99934z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default LogoShort;
