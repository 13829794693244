import React from "react";
import Button from "@/components/button";
import Container from "@/components/container";
import Link from "@/components/link";
import Logo from "@/layouts/partials/logo";

const Header: React.FC = () => (
  <nav className="relative z-50">
    <Container className="flex items-center justify-between text-base font-medium py-lg gap-md">
      <Link to="/" className="text-accent-500" aria-label="Link4Health">
        <Logo className="w-auto h-5 md:h-6 xl:h-auto" aria-hidden="true" />
      </Link>
      <div className="flex items-center gap-base">
        <ul className="items-center hidden gap-base md:flex">
          <li>
            <Link to="/#cardlink">Was ist CardLink?</Link>
          </li>
        </ul>
        <Button to="mailto:info@link4.health?subject=Anfrage%20zu%20CardLink">
          Anfrage
          <span className="sr-only sm:not-sr-only">&nbsp;zu&nbsp;CardLink</span>
        </Button>
      </div>
    </Container>
  </nav>
);

export default Header;
